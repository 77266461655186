import React from 'react'
import { Link } from 'gatsby'
import Nav from './nav'
import Cookies from "universal-cookie";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: "",
      hasCookie: false,
    }
  }

  componentDidMount() {
    var path = window.location.pathname.split("/")[1];
    const cookies = new Cookies();

    this.setState({
      pathname: path,
      hasCookie: cookies.get('studio') == undefined ? false : true
    })
  }

  render() {
    const data = this.props.data.optionsACF;

    return (
      <header className={`${this.state.pathname == '' ? 'login' : ''} ${this.state.pathname != 'team' ? 'transparent-background' : 'team-member'}`}>
        <div className="ctn-main flex-ctn">
          {data.header.websiteLogo !== undefined &&
            <div className="img-ctn">
              {this.state.hasCookie == true ? (
                <Link to="/home">
                  <img className="imgPath" src={data.header.websiteLogo.sourceUrl} alt="Edelman digital logo" />
                </Link>
              ) : (
                <img className="imgPath" src={data.header.websiteLogo.sourceUrl} alt="Edelman digital logo" />
              )}
            </div>
          }
          <Nav link={this.props.navItems} data={this.props.data} />
        </div>
      </header>
    );
  }
}

export default Header;