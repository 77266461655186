import React, { Component } from 'react';
import { withPrefix } from 'gatsby-link';

class Footer extends Component {
  render() {
    const optionsACF = this.props.data.optionsACF;

    return (
      <footer className="uk-padding">
        <div className="ctn-main uk-grid-collapse uk-child-width-expand@s" data-uk-grid>
          <div className="uk-text-left">
            {optionsACF.footer.title !== "" &&
              <h3 dangerouslySetInnerHTML={{ __html: optionsACF.footer.title }} />
            }
            {optionsACF.footer.description !== "" &&
              <div className="description" dangerouslySetInnerHTML={{ __html: optionsACF.footer.description }} />
            }
            {optionsACF.footer.email !== "" &&
              <a className="email mobile-only" href={`mailto: ${optionsACF.footer.email}`}>
                <img src={withPrefix("img/email-icon.png")} alt="Email icon"/>
                {optionsACF.footer.email}
              </a>
            }
          </div>
          <div className="uk-text-center uk-flex uk-flex-center uk-flex-middle">
          {optionsACF.header.website_logo !== undefined &&
            <img className="footer-logo" src={optionsACF.header.website_logo.source_url} alt="Edelman digital logo" />
          }

          </div>
          <div className="uk-text-right">
          {optionsACF.footer.phone !== "" &&
            <a className="phone" href={`tel: ${optionsACF.footer.phone}`} dangerouslySetInnerHTML={{ __html: optionsACF.footer.phone}} />
          }
          {optionsACF.footer.address !== "" &&
            <div className="address" dangerouslySetInnerHTML={{ __html: optionsACF.footer.address }} />
          }
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
