import React, { Component } from 'react';
import { Link } from 'gatsby'

class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: ""
    }
  }

  componentDidMount() {
    var path = window.location.pathname.split("/")[1];

    this.setState({
      pathname: path
    })
  }

  render() {
    const footerFields = this.props.data.optionsACF.footer;
    const navLinks = this.props.link.menuItems.nodes ;

    return (
      <div className="navigation-ctn">
        {this.state.pathname != "" &&
          <>
            <button className="burger-btn" aria-controls="menu" aria-expanded="false" title="Menu toggle">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div className="aside-ctn">
              <nav>
                {navLinks.map(( node, index ) => {
                  return (
                    <div key={index}>
                      <Link to={node.url} activeClassName="current-item" dangerouslySetInnerHTML={{ __html: node.label}} />
                    </div>
                  )
                })}
              </nav>
              <div className="mobile-only header-contact">
                {footerFields.title !== "" &&
                  <h3 dangerouslySetInnerHTML={{ __html: footerFields.title }} />
                }
                {footerFields.phone !== "" &&
                  <a className="phone" href={`tel: ${footerFields.phone}`} dangerouslySetInnerHTML={{ __html: footerFields.phone}} />
                }
                {footerFields.email !== "" &&
                  <a className="email" href={`mailto: ${footerFields.email}`} dangerouslySetInnerHTML={{ __html: footerFields.email}} />
                }
              </div>
            </div>
          </>
        }
      </div>
    );
  }
}

export default Nav;