import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Footer from './footer'
import Header from './header'
import '../styles/styles.scss'


if (typeof window !== 'undefined') {
  const uikit = require('uikit');
  const icons = require('uikit/dist/js/uikit-icons.min');
  uikit.use(icons);
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        wp {
          optionsPage {
            optionsACF {
              header {
                websiteLogo {
                  sourceUrl
                }
              }
              footer {
                title
                description
                phone
                address
                email
              }
            }
          }
        }
        wpMenu(locations: {eq: PRIMARY}) {
          id
          name
          menuItems {
            nodes {
              label
              url
            }
          }
        }

      }
    `}
    render={data => (
      <>
        <div>
          <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <meta name="description" content="Simple description" />
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/uikit@3.5.0/dist/css/uikit.min.css" />
            <script src="https://code.jquery.com/jquery-3.3.1.min.js" type="text/javascript" />
            <script src="https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.min.js" type="text/javascript" />
            <script src="https://cdn.jsdelivr.net/npm/uikit@3.5.0/dist/js/uikit-icons.min.js"></script>
          </Helmet>

          <Header data={data.wp.optionsPage}
            navItems={data.wpMenu} />

          <div className="main-content">
            {children}
          </div>

          <Footer data={data.wp.optionsPage} />
        </div>
      </>
    )}
  />
)


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
